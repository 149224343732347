<template>
	<lesson-resource-item-base :resource="resource">
		<tooltip
			v-if="getDescription"
			:text="getDescription"
			position="left"
		>
			<icon
				data-element="description-icon"
				icon="info"
				colour="grey"
			/>
		</tooltip>
		<btn
			text="Edit"
			@click.native="handleEdit"
			size="sm"
		/>
		<btn
			text="Delete"
			@click.native="handleDelete"
			size="sm"
			colour="red"
		/>
	</lesson-resource-item-base>
</template>

<script>

	import LessonResourceItemBase from '@/components/lessons/LessonResourceItemBase';
	import Icon from '@/components/ui/Icon';
	import Tooltip from '@/components/ui/Tooltip';
	import Btn from '@/components/ui/Btn';

	export default {
		components: {
			LessonResourceItemBase,
			Icon,
			Tooltip,
			Btn
		},
		props: {
			resource: {
				type: Object,
				default: () => ({})
			}
		},
		computed: {
			getDescription () {
				return this.resource?.description;
			}
		},
		methods: {
			handleEdit () {
				this.$emit('edit', this.resource);
			},
			handleDelete () {
				if (confirm('Are you sure?')) {
					this.$emit('delete', this.resource);
				}
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='lesson-resource-item-base'] {
		padding:rem(5) 0;
		[data-element='description-icon'] {
			flex-shrink:0;
			width:rem(28);
			height:auto;
			cursor: pointer;
		}
		[data-component='btn'] {
			margin-left:rem(8);
		}
	}

</style>
