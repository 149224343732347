<template>
	<div
		data-component="accordion"
		:data-expanded="expanded"
		:data-disabled="disabled"
	>
		<div data-element="title">
			<slot
				name="title"
				:expanded="expanded"
				:toggle="toggle"
			/>
		</div>
		<slide-up-down :active="expanded">
			<div data-element="content">
				<slot />
			</div>
		</slide-up-down>
	</div>
</template>

<script>

	export default {
		components: {},
		props: {
			disabled: {
				type: Boolean,
				default: false
			}
		},
		data () {
			return {
				expanded: false
			};
		},
		methods: {
			toggle () {
				if (this.disabled) {
					return;
				}
				this.expanded = !this.expanded;
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='accordion'] {
		display:flex;
		flex-direction: column;
  }

</style>
