<template>
	<div data-component="resources-edit-modal">
		<btn
			text="Add resource"
			@click.native="createMedia"
		/>
		<lesson-resources-by-categories :media="getMedia">
			<template v-slot="{ resource }">
				<editable-lesson-resource-item
					:resource="resource"
					@edit="editMedia"
					@delete="deleteMedia"
				/>
			</template>
			<template #empty-state>
				<p data-element="list-empty-state">
					List is empty
				</p>
			</template>
		</lesson-resources-by-categories>
	</div>
</template>

<script>

	import Btn from '@/components/ui/Btn';
	import LessonResourcesByCategories from '@/components/lessons/LessonResourcesByCategories';
	import EditableLessonResourceItem from '@/components/lessons/EditableLessonResourceItem';
	import routeParams from '@/mixins/routeParams';
	import api from '@/services/api';

	export default {
		components: {
			Btn,
			LessonResourcesByCategories,
			EditableLessonResourceItem
		},
		mixins: [routeParams],
		props: {
			lesson: {
				type: Object,
				default: undefined
			}
		},
		data: () => ({
			form: undefined,
			isWaiting: false
		}),
		computed: {
			getMedia () {
				return this.lesson.media || [];
			}
		},
		methods: {
			createMedia () {
				this.$emit('createMedia');
			},
			editMedia (mediaItem) {
				this.$emit('editMedia', mediaItem);
			},
			async deleteMedia (mediaItem) {
				this.isWaiting = true;
				const response = await api[this.getApiRoot].deleteMediaByLessonIdAndMediaId({
					lessonId: this.getLessonId,
					mediaId: mediaItem.id
				});
				if (!response) {
					return false;
				}
				this.lesson.media = this.lesson.media.filter((thisMediaItem) => {
					return mediaItem.id !== thisMediaItem.id;
				});
				this.isWaiting = false;
				this.$store.commit('ui/showNotification', {
					notification: 'The resource was successfully deleted.'
				});
			}
		}
	};
</script>

<style lang="scss" scoped>

	[data-component='resources-edit-modal'] {
		display:flex;
		flex-direction: column;
		[data-component='btn'] {
			margin:0 0 rem(12) auto;
		}
		[data-element='list-empty-state'] {
			width:100%;
			font-size:rem(18);
			color:$c-brand-grey;
			text-align:center;
		}
	}

</style>
