<template>
	<div
		data-component="activity-list-item"
	>
		<div data-element="details">
			<p data-element="title">
				{{ getTitleString }}
			</p>
			<p
				v-if="getActivityInfoString"
				data-element="info"
			>
				<icon
					v-if="getCompletedIcon"
					icon="check"
					colour="grey"
				/>
				{{ getActivityInfoString }}
			</p>
		</div>
		<btn
			v-if="getActivityRoute"
			:text="getBtnText"
			:route="getActivityRoute"
			size="lg"
			:colour="getBtnColour"
		/>
	</div>
</template>

<script>

	import Icon               from '@/components/ui/Icon';
	import Btn                from '@/components/ui/Btn';
	import routeParams        from '@/mixins/routeParams';
	import { activityTypes }  from '@/consts';

	export default {
		components: {
			Icon,
			Btn
		},
		mixins: [routeParams],
		props: {
			activity: {
				type: Object,
				default: () => ({})
			},
			activityNumber: {
				type: Number,
				default: undefined
			},
			isLive: {
				type: Boolean,
				default: false
			}
		},
		computed: {
			getActivityId () {
				return this.activity?.id;
			},
			getCompletedIcon () {
				if (!this.activity?.isComplete) {
					return false;
				}
				return true;
			},
			getTitleString () {
				if (!this.activity?.title ||
						!this.activityNumber) {
					return false;
				}
				return `Activity ${this.activityNumber} - ${this.activity.title}`;
			},
			getActivityInfoString () {
				if (!this.activityNumber ||
						!this.getType) {
					return false;
				}
				return `${this.getType}`;
			},
			getType () {
				if (!this.activity?.type) {
					return false;
				}
				return activityTypes.find((activityType) => {
					return activityType.value === this.activity.type;
				}).text;
			},
			getBtnColour () {
				if (this.activity?.isComplete) {
					return 'blue-lighter-2';
				}
				return 'blue-lighter-1';
			},
			getBtnText () {
				if (this.activity?.isComplete) {
					return 'Restart';
				}
				return 'Start';
			},
			getActivityRoute () {
				if (this.getContext === 'myCourses' &&
						this.isLive) {
					return false;
				}
				const contextSlug = this.getContextSlug;
				if (contextSlug === 'my-courses') {
					return `/${contextSlug}/${this.getCourseId}/lessons/${this.getLessonId}/activities/${this.getActivityId}`;
				}
				return `/${contextSlug}/courses/${this.getCourseId}/lessons/${this.getLessonId}/activities/${this.getActivityId}/view`;
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='activity-list-item'] {
		display:flex;
		padding:rem(28) rem(24);
		[data-element='details'] {
			margin:auto rem(24) auto 0;
			[data-element='title'] {
				margin:0 0 rem(4);
				font-family:'Montserrat', sans-serif;
				font-size:rem(18);
				line-height:1.3;
				font-weight:700;
				color:$c-brand-blue-lighter-1;
			}
			[data-element='info'] {
				display:inline-flex;
				align-items:center;
				margin:0;
				[data-component='icon'] {
					width:rem(22);
					height:auto;
					margin-right:rem(4);
				}
				span {
					font-size:rem(16);
					line-height:1.3;
					color:$c-brand-grey;
				}
			}
		}
		[data-component='btn'] {
			margin:auto 0 auto auto;
		}
	}

</style>
