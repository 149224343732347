<template>
	<div data-component="lesson-view">
		<article>
			<live-or-solo-badge
				:isLive="getIsLive"
				courseOrLesson="lesson"
			/>
			<h1
				v-if="getLessonTitle"
				data-element="heading"
			>
				{{ getLessonTitle }}
			</h1>
			<p
				v-if="getDescription"
				data-element="subheading"
			>
				{{ getDescription }}
			</p>
			<video-viewer
				v-if="getVideoSrc"
				:src="getVideoSrc"
			/>
			<div
				data-element="main-cta"
				v-if="getShowMainCta"
			>
				<btn
					v-if="getZoomLink"
					text="View lesson on Zoom"
					:url="getZoomLink"
					:ghost="true"
					size="xl"
				/>
				<btn
					v-if="getZoomDownloadLink"
					text="View replay on Zoom"
					:url="getZoomDownloadLink"
					:ghost="true"
					size="xl"
				/>
			</div>
			<grouped-activity-list
				:activities="getActivities"
				:isLive="getIsLive"
			/>
			<lesson-action-groups
				v-if="getLessonIsLoaded"
				:lesson="lesson"
			/>
		</article>
		<aside>
			<additional-info-list
				:info="getAdditionalInfo"
			/>
			<lesson-resources
				:lesson="lesson"
			/>
			<lesson-deals
				v-if="getShowLessonDeals"
				:lesson="lesson"
				@reloadLesson="handleReloadLesson"
			/>
			<lesson-action-list
				:lesson="lesson"
			/>
		</aside>
	</div>
</template>

<script>

	import LiveOrSoloBadge     from '@/components/ui/LiveOrSoloBadge';
	import VideoViewer         from '@/components/uploads/VideoViewer';
	import Btn                 from '@/components/ui/Btn';
	import GroupedActivityList        from '@/components/lessons/GroupedActivityList';
	import AdditionalInfoList  from '@/components/ui/additionalInfoList/AdditionalInfoList';
	import LessonResources     from '@/components/lessons/LessonResources';
	import LessonDeals         from '@/components/lessons/LessonDeals';
	import LessonActionGroups  from '@/components/lessons/LessonActionGroups';
	import LessonActionList    from '@/components/lessons/LessonActionList';
	import actionClick         from '@/mixins/actionClick';
	import routeParams         from '@/mixins/routeParams';
	import dayjs               from 'dayjs';

	export default {
		components: {
			LiveOrSoloBadge,
			VideoViewer,
			Btn,
			GroupedActivityList,
			AdditionalInfoList,
			LessonResources,
			LessonDeals,
			LessonActionGroups,
			LessonActionList
		},
		mixins: [actionClick, routeParams],
		props: {
			lesson: {
				type: Object,
				default: undefined
			}
		},
		computed: {
			getLessonIsLoaded () {
				return this.lesson?.id !== undefined;
			},
			getIsLive () {
				return this.lesson?.live;
			},
			getCourseTitle () {
				return this.lesson?.courseTitle;
			},
			getLessonTitle () {
				return this.lesson?.title;
			},
			getDescription () {
				return this.lesson?.description;
			},
			getRecordingMedia () {
				return this.lesson?.media?.filter((media) => {
					return media.config.type === 'recording';
				});
			},
			getVideoSrc () {
				if (!this.getRecordingMedia?.[0]?.url) {
					return false;
				}
				return this.getRecordingMedia[0].url;
			},
			getZoomLink () {
				if (this.lesson?.schedule?.endedAt) {
					return false;
				}
				return this.lesson?.schedule?.zoomLink;
			},
			getZoomDownloadLink () {
				return this.lesson?.schedule?.zoomDownload;
			},
			getShowMainCta () {
				if (!this.getZoomLink &&
						!this.getZoomDownloadLink) {
					return false;
				}
				return true;
			},
			getActivities () {
				return this.lesson?.activities;
			},
			getScheduleString () {
				if (!this.lesson.live) {
					return false;
				}
				if (!this.lesson?.schedule?.startsAt) {
					return false;
				}
				const startsAt = this.lesson.schedule.startsAt;
				const startedAt = this.lesson.schedule.startedAt;
				const endedAt = this.lesson.schedule.endedAt;
				if (endedAt) {
					const time = this.$store.getters['auth/getTimeInLocalTimezone']({
						time: endedAt
					});
					return `Ended at ${time}`;
				}
				if (startedAt && !endedAt) {
					return 'Live now';
				}
				const time = this.$store.getters['auth/getTimeInLocalTimezone']({
					time: startsAt
				});
				return `Starts at ${time}`;
			},
			getWillUpload () {
				if (!this.lesson?.schedule?.willUpload) {
					return false;
				}
				if (this.lesson?.schedule?.zoomDownload ||
						this.getVideoSrc) {
					return false;
				}
				return true;
			},
			getAdditionalInfo () {
				return [
					(this.getScheduleString ?
						{
							heading: 'Schedule',
							text: this.getScheduleString
						} : false
					),
					(this.getWillUpload ?
						{
							heading: 'Lesson replay',
							text: 'Teacher will upload a replay'
						} : false
					)
				].filter(Boolean);
			},
			getShowLessonDeals () {
				return this.lesson.deals?.length > 0;
			}
		},
		methods: {
			getDateTime (value) {
				return dayjs(value).format('DD/MM/YYYY HH:mm:ss');
			},
			handleReloadLesson () {
				this.$emit('reloadLesson');
			}
		}
	};

</script>

<style lang="scss" scoped>
	[data-component='lesson-view'] {
		@include nested-grid;
		article {
			@include nested-cols(12, 12);
			margin-bottom:rem(64);
			@include mq('sm') {
				@include nested-cols(7, 12);
				@include nested-gut(12);
				margin-bottom:0;
			}
			[data-element='live-or-solo-badge'] {
			}
			[data-element='heading'] {
				margin:rem(40) 0 rem(8);
				font-family:'Montserrat', sans-serif;
				font-size:rem(34);
				font-weight:700;
				color:$c-brand-blue;
			}
			[data-element='subheading'] {
				margin: 0 0 rem(58);
				font-size:rem(18);
				color:$c-brand-blue;
			}
			[data-component='video-viewer'] {
				margin:0 0 rem(44);
			}
			[data-element='main-cta'] {
				display:flex;
				margin:0 0 rem(60);
				[data-component='btn'] {}
			}
			[data-component='activity-list'] {
			}
			[data-component='lesson-action-groups'] {
				margin-top:rem(48);
			}
		}
		aside {
			@include nested-cols(12, 12);
			@include mq('sm') {
				@include nested-cols(4, 12);
				@include nested-push(1, 12);
			}
			[data-component='additional-info-list'] {
				margin-bottom:rem(22);
			}
			[data-component='lesson-resources'] {
				margin-bottom:rem(16);
			}
			[data-component='lesson-deals'] {
				margin-bottom:rem(16);
			}
		}
	}
</style>
