<template>
	<div
		v-if="getActivities"
		data-component="activity-list"
	>
		<h2 data-element="heading">
			Activities
		</h2>
		<div data-element="activities">
			<template v-for="(item, key) in getGroupedActivities">
				<template v-if="item.isGroup">
					<accordion
						v-if="item.groupItems.length > 1"
						:key="key"
						data-ordered
					>
						<template #title="{ expanded, toggle }">
							<div data-element="group-header">
								<div data-element="details">
									<p data-element="title">
										{{ getGroupTitle(item.groupType) }}
									</p>
									<p data-element="info">
										{{ getGroupInfo(item) }}
									</p>
								</div>
								<btn
									:text="getToggleBtnText(expanded)"
									size="sm"
									colour="grey"
									@click.native="toggle"
								/>
								<btn
									:text="getGroupBtnText(item)"
									size="lg"
									:colour="getGroupBtnColour(item)"
									:route="getActivityRouteForGroup(item)"
								/>
							</div>
						</template>
						<template #default>
							<div data-element="group-list">
								<activity-list-item
									v-for="(nestedActivity, nestedKey) in item.groupItems"
									:key="nestedKey"
									:activityNumber="nestedActivity.index + 1"
									:activity="nestedActivity"
									:isLive="isLive"
									data-ordered
								/>
							</div>
						</template>
					</accordion>
					<activity-list-item
						v-else-if="getFirstItem(item.groupItems)"
						:key="key"
						:activityNumber="getFirstItem(item.groupItems).index + 1"
						:activity="getFirstItem(item.groupItems)"
						:isLive="isLive"
						data-ordered
					/>
				</template>
				<activity-list-item
					v-else
					:key="key"
					:activityNumber="item.index + 1"
					:activity="item"
					:isLive="isLive"
					data-ordered
				/>
			</template>
		</div>
	</div>
</template>

<script>

	import Accordion from '@/components/ui/Accordion';
	import Btn from '@/components/ui/Btn';
	import ActivityListItem from '@/components/lessons/ActivityListItem';
	import routeParams from '@/mixins/routeParams';
	import { pluralize } from '@/helpers';

	export default {
		components: {
			Accordion,
			Btn,
			ActivityListItem
		},
		mixins: [routeParams],
		props: {
			activities: {
				type: Array,
				default: () => ([])
			},
			isLive: {
				type: Boolean,
				default: false
			}
		},
		data: () => ({
			groupTypes: ['quiz']
		}),
		computed: {
			getActivities () {
				return this.activities?.length ? this.activities : [];
			},
			getGroupedActivities () {
				return this.getActivities
					.map((item, index) => ({ ...item, index  }))
					.reduce((acc, curr) => {
						if (this.groupTypes.includes(curr.type)) {
							const lastIndex = acc.length - 1;
							const groupType = `${curr.type}_group`;
							const prev = acc[lastIndex];
							if (prev && prev.groupType === groupType) {
								acc[lastIndex].groupItems = [...prev.groupItems, curr];
							} else {
								acc.push({
									isGroup: true,
									nestedType: curr.type,
									groupType,
									groupItems: [curr]
								});
							}
						} else {
							acc.push(curr);
						}

						return acc;
					}, []);
			}
		},
		methods: {
			getGroupTitle (groupType) {
				if (groupType === 'quiz_group') {
					return 'Quiz';
				}

				return groupType;
			},
			getGroupItemsCount (group) {
				return group.groupItems.length;
			},
			getGroupItemsName (groupType) {
				if (groupType === 'quiz_group') {
					return 'question';
				}

				return 'item';
			},
			getGroupInfo (group) {
				const count = this.getGroupItemsCount(group);
				const name = this.getGroupItemsName(group.groupType);

				return `${count} ${pluralize(count, name)}`;
			},
			getToggleBtnText (expanded) {
				return expanded ? 'Hide' : 'Show';
			},
			getFirstItem (list) {
				return list.find(Boolean);
			},
			areAllActivitiesCompleted (activities) {
				return activities.every(item => item.isComplete);
			},
			findFirstNotCompleted (activities) {
				return activities.find(item => !item.isComplete);
			},
			getActivityBaseRoute (id) {
				if (this.getContext === 'myCourses' &&
					this.isLive) {
					return false;
				}
				const contextSlug = this.getContextSlug;
				if (contextSlug === 'my-courses') {
					return `/${contextSlug}/${this.getCourseId}/lessons/${this.getLessonId}/activities/${id}`;
				}
				return `/${contextSlug}/courses/${this.getCourseId}/lessons/${this.getLessonId}/activities/${id}/view`;
			},
			getGroupBtnText (group) {
				const isGroupComplete = this.areAllActivitiesCompleted(group.groupItems);
				if (isGroupComplete) {
					return 'Restart';
				}
				return 'Start';
			},
			getGroupBtnColour (group) {
				const isGroupComplete = this.areAllActivitiesCompleted(group.groupItems);
				if (isGroupComplete) {
					return 'blue-lighter-2';
				}
				return 'blue-lighter-1';
			},
			getActivityRouteForGroup (group) {
				const isGroupComplete = this.areAllActivitiesCompleted(group.groupItems);
				if (isGroupComplete) {
					// Restart from first activity in group
					return this.getActivityBaseRoute(group.groupItems[0].id);
				}
				const firstNotCompletedActivity = this.findFirstNotCompleted(group.groupItems);
				if (firstNotCompletedActivity) {
					return this.getActivityBaseRoute(firstNotCompletedActivity.id);
				}
				return false;
			}
		}
	};

</script>

<style lang="scss" scoped>
	[data-component='activity-list'] {
		border: 4px solid $c-brand-grey-lighter-5;
		border-radius: 8px;
		[data-element='heading'] {
			margin: rem(22) rem(24);
			font-size: rem(22);
			font-weight: 700;
			color: $c-brand-blue;
		}
		[data-element='activities'] {
			[data-ordered]:nth-of-type(2n + 1) {
				&[data-component='activity-list-item'] {
					background-color: $c-brand-grey-lighter-7;
				}

				[data-element='group-header'] {
					background-color: $c-brand-grey-lighter-7;
				}
			}
			[data-element='group-header'] {
				display: flex;
				align-items: center;
				padding: rem(28) rem(24);
				[data-element='details'] {
					[data-element='title'] {
						margin: 0 0 rem(4);
						font-family: 'Montserrat', sans-serif;
						font-size: rem(18);
						line-height: 1.3;
						font-weight: 700;
						color: $c-brand-blue-lighter-1;
					}

					[data-element='info'] {
						display: inline-flex;
						align-items: center;
						margin: 0;
					}
				}

				[data-component='btn']:last-child {
					margin-left: rem(18);
				}

				[data-component='btn']:not(:last-child) {
					margin-left: auto;
				}

			}

			[data-element='group-list'] {
				border:4px solid $c-brand-grey-lighter-5;
				border-radius: 8px;
			}

			::v-deep [data-component='accordion'] {
				[data-element='content'] {
					padding: rem(15);
				}
			}
		}
	}
</style>
