<template>
	<div data-component="resource-details-form">
		<validation-observer
			data-element="form"
			tag="form"
			ref="form"
			@submit.prevent="submit"
		>
			<select-field
				name="Category"
				rules="required"
				:options="getCategoryOptions"
				:emptyAllowed="false"
				v-model="category"
			/>
			<textarea-field
				name="description"
				label="Description"
				v-model="description"
			/>
			<lesson-resource-item-base
				v-if="isEditMode"
				:resource="resource"
			/>
			<upload
				v-else
				name="Resource file"
				:rules="rules"
				:form.sync="form"
				:helpText="helpText"
			/>
			<actions
				:actions="getActions"
				:isWaiting="getIsWaiting"
				@actionClick="onActionClick"
			/>
		</validation-observer>
	</div>
</template>

<script>

	import SelectField from '@/components/forms/SelectField';
	import TextareaField from '@/components/forms/TextareaField';
	import LessonResourceItemBase from '@/components/lessons/LessonResourceItemBase';
	import Upload from '@/components/uploads/Upload';
	import Actions from '@/components/ui/Actions';
	import routeParams from '@/mixins/routeParams';
	import actionClick from '@/mixins/actionClick';
	import { defaultResourceCategory, resourceCategoryOptions } from '@/consts';

	export default {
		components: {
			SelectField,
			TextareaField,
			LessonResourceItemBase,
			Upload,
			Actions
		},
		mixins: [routeParams, actionClick],
		props: {
			isEditMode: {
				type: Boolean,
				default: false
			},
			isWaiting: {
				type: Boolean,
				default: false
			},
			resource: {
				type: [Object, undefined],
				default: undefined
			}
		},
		data: () => ({
			category: defaultResourceCategory,
			description: '',
			form: undefined,
			rules: {
				required: true,
				lessonResources: 'pbn|lin|pdf|doc|docx|ppt|pptx|pps|mp4'
			},
			helpText: [
				'You can upload a pbn, lin, pdf, doc, docx, ppt, pptx, pps or mp4 file'
			]
		}),
		computed: {
			getCategoryOptions () {
				return resourceCategoryOptions;
			},
			getActions () {
				return {
					primary: [
						{
							text: 'Save',
							type: 'submit',
							btnId: 'save'
						}
					],
					secondary: [
						{
							text: 'Cancel',
							actionId: 'cancel'
						}
					]
				};
			},
			getIsWaiting () {
				if (this.isWaiting) {
					return 'save';
				}
				return undefined;
			}
		},
		created () {
			this.init();
		},
		methods: {
			init () {
				if (this.isEditMode && this.resource) {
					this.category = this.resource.config.type;
					this.description = this.resource.description;
				}
			},
			async submit () {
				const valid = await this.$refs.form.validate();
				if (!valid) {
					return;
				}
				this.$emit('save', {
					category: this.category,
					description: this.description,
					form: this.form
				});
			},
			cancel () {
				this.$emit('cancel');
			}
		}
	};
</script>

<style lang="scss" scoped>

	[data-component='resource-details-form'] {
		[data-element='form'] {
			margin-bottom: 0;
			[data-component='actions'] {
				padding-bottom: rem(10);
			}
		}
	}

</style>
