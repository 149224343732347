<template>
	<lesson-resource-item-base :resource="resource">
		<tooltip
			v-if="getDescription"
			:text="getDescription"
			position="left"
		>
			<icon
				data-element="description-icon"
				icon="info"
				colour="grey"
			/>
		</tooltip>
	</lesson-resource-item-base>
</template>

<script>

	import LessonResourceItemBase from '@/components/lessons/LessonResourceItemBase';
	import Icon from '@/components/ui/Icon';
	import Tooltip from '@/components/ui/Tooltip';

	export default {
		components: {
			LessonResourceItemBase,
			Icon,
			Tooltip
		},
		props: {
			resource: {
				type: Object,
				default: () => ({})
			}
		},
		computed: {
			getDescription () {
				return this.resource?.description;
			}
		},
		methods: {}
	};

</script>

<style lang="scss" scoped>

	[data-element='description-icon'] {
		flex-shrink:0;
		width:rem(28);
		height:auto;
		cursor: pointer;
	}

</style>
