<template>
	<div data-component="activites-modal">
		<div
			data-element="header"
		>
			<drop-menu
				v-if="getDropMenuOptions"
				:options="getDropMenuOptions"
				caption="New activity"
			/>
		</div>
		<draggable-list
			v-if="activities"
			:items="activities"
			:courseId="getCourseId"
			:lessonId="getLessonId"
			:type="'activities'"
			@reordered="onReordered"
		/>
	</div>
</template>

<script>

	import DropMenu           from '@/components/ui/DropMenu';
	import DraggableList      from '@/components/ui/views/draggableList/DraggableList';
	import actionClick        from '@/mixins/actionClick';
	import routeParams        from '@/mixins/routeParams';
	import api                from '@/services/api';
	import { activityTypes }  from '@/consts';

	export default {
		components: {
			DropMenu,
			DraggableList
		},
		mixins: [actionClick, routeParams],
		props: {
			lesson: {
				type: Object,
				default: undefined
			}
		},
		data: () => ({
			activities:  [],
			options:     activityTypes
		}),
		computed: {
			isLive () {
				if (!this.lesson) {
					return false;
				}
				return this.lesson.live;
			},
			getDropMenuOptions () {
				if (this.getContext === 'admin') {
					return false;
				}
				const opts = this.options.map((activity) => ({
					...activity,
					action:	() => {
						this.$router.push(`/teaching/courses/${this.getCourseId}/lessons/${this.getLessonId}/activities/create?type=${activity.value}`);
					}
				}));
				if (!this.isLive) {
					return opts.filter(activity => activity.value !== 'deal');
				}
				return opts;
			}
		},
		created () {
			this.setActivities();
		},
		methods: {
			async onReordered (reorderedActivities) {
				const response = await api[this.getApiRoot].reorderActivitiesByLessonId({
					lessonId: this.getLessonId,
					activities: reorderedActivities.map((activity) => {
						return activity.id;
					})
				});
				if (!response) {
					return false;
				}
				this.$emit('updateActivitiesOrder', reorderedActivities);
				this.$store.commit('ui/showNotification', {
					notification: 'New order has been saved'
				});
			},
			async setActivities () {
				const response = await api[this.getApiRoot].getActivitiesByLessonId({
					lessonId: this.getLessonId
				});
				if (!response) {
					// this.$store.commit('ui/showError');
					return false;
				}
				this.activities = response;
				// this.$store.commit('ui/setLoadingIsHidden');
			}
		}
	};
</script>

<style lang="scss" scoped>
	[data-component='activites-modal'] {
		[data-element='header'] {
			display:flex;
			justify-content:flex-end;
		}
	}
</style>
