<template>
	<div data-component="textarea-field">
		<label
			data-element="label"
			:for="id"
		>
			{{ label }}
		</label>
		<textarea
			:id="id"
			:name="id"
			type="textarea"
			:value="value"
			@input="onInput"
		/>
	</div>
</template>

<script>

	export default {
		props: {
			id: {
				type: String,
				default: ''
			},
			value: {
				type: String,
				default: ''
			},
			label: {
				type: String,
				default: ''
			}
		},
		methods: {
			onInput (event) {
				this.$emit('input', event.target.value);
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='textarea-field'] {
		textarea {
			width: 100%;
			height: 128px;
			padding: 6px 12px;
			border: 1px solid $c-brand-grey-lighter-7;
			border-radius: 5px;
			font-size:rem(16);
			&:hover,
			&:focus,
			&:focus-visible {
				border: 1px solid $c-brand-grey-lighter-2;
				outline: none;
			}
		}
	}

</style>
