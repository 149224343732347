<template>
	<div
		data-component="lesson-action-groups"
		v-if="getShowGroups"
	>
		<action-groups
			:groups="getGroups"
			@actionClick="onActionClick"
		/>
		<portal
			to="modal"
			v-if="deleteConfirmationIsVisible"
		>
			<modal-inner
				@close="toggleDeleteConfirmation"
				heading="Delete lesson"
			>
				<confirmation-modal
					text="Deleting the lesson will permanently remove it from the course."
					confirmationText="Delete"
					confirmationActionId="deleteLesson"
					cancelActionId="toggleDeleteConfirmation"
					@actionClick="onActionClick"
				/>
			</modal-inner>
		</portal>
	</div>
</template>
<script>

	import ActionGroups        from '@/components/ui/actionGroups/ActionGroups';
	import ModalInner          from '@/components/ui/modal/ModalInner';
	import ConfirmationModal   from '@/components/ui/modal/ConfirmationModal';
	import actionClick         from '@/mixins/actionClick';
	import routeParams         from '@/mixins/routeParams';
	import api                 from '@/services/api';

	export default {
		components: {
			ActionGroups,
			ModalInner,
			ConfirmationModal
		},
		mixins: [actionClick, routeParams],
		props: {
			lesson: {
				type: Object,
				default: undefined
			}
		},
		data: () => ({
			deleteConfirmationIsVisible: false
		}),
		computed: {
			getShowGroups () {
				return this.getGroups.filter((group) => {
					return group.rows.length > 0;
				}).length > 0;
			},
			getShowStartLesson () {
				if (this.getContext !== 'teaching') {
					return false;
				}
				if (!this.lesson?.live) {
					return false;
				}
				if (this.lesson?.schedule?.startedAt) {
					return false;
				}
				return true;
			},
			getShowEndLesson () {
				if (this.getContext !== 'teaching') {
					return false;
				}
				if (!this.lesson?.live) {
					return false;
				}
				if (!this.lesson?.schedule?.startedAt) {
					return false;
				}
				if (this.lesson?.schedule?.endedAt) {
					return false;
				}
				return true;
			},
			getShowDelete () {
				if (this.getContext !== 'teaching' &&
						this.getContext !== 'admin') {
					return false;
				}
				return true;
			},
			getStartedAtString () {
				if (!this.lesson?.schedule?.startedAt) {
					return false;
				}
				const time = this.$store.getters['auth/getTimeInLocalTimezone']({
					time: this.lesson.schedule.startedAt
				});
				return time;
			},
			getGroups () {
				return [
					{
						heading: 'Scheduling',
						rows: [
							(this.getShowStartLesson ?
								{
									infoText: 'Lesson has not started yet',
									action: {
										text: 'Start lesson',
										description: 'Mark the lesson as started so students can see it’s in progress',
										actionId: 'startLesson'
									}
								} : false
							),
							(this.getShowEndLesson ?
								{
									infoText: `Lesson started at ${this.getStartedAtString}`,
									action: {
										text: 'End lesson',
										description: 'Mark the lesson as ended so students can see it’s complete',
										actionId: 'endLesson'
									}
								} : false
							)
						].filter(Boolean)
					},
					{
						heading: 'Deleting',
						rows: [
							(this.getShowDelete ?
								{
									infoText: 'Cannot be undone',
									action: {
										text: 'Delete',
										description: 'Permanently remove this lesson from the course',
										actionId: 'toggleDeleteConfirmation'
									}
								} : false
							)
						].filter(Boolean)
					}
				];
			}
		},
		methods: {
			getISO () {
				return this.$store.getters['auth/getISOTimeString']({
					time: new Date()
				});
			},
			toggleDeleteConfirmation () {
				this.deleteConfirmationIsVisible = !this.deleteConfirmationIsVisible;
			},
			async deleteLesson () {
				const response = await api[this.getApiRoot].deleteLessonById({
					lessonId: this.getLessonId
				});
				if (!response) {
					return false;
				}
				const contextSlug = this.getContextSlug;
				const courseId = this.getCourseId;
				const route = `/${contextSlug}/courses/${courseId}`;
				await this.$router.push(route);
				this.$store.commit('ui/showNotification', {
					notification: 'The lesson has been deleted.'
				});
			},
			async startLesson () {
				const lesson = await api.teaching.updateLessonById({
					lessonId: this.getLessonId,
					lesson: {
						...this.lesson,
						schedule: {
							...this.lesson.schedule,
							startedAt: this.getISO()
						}
					}
				});
				if (!lesson) {
					return false;
				}
				this.lesson.schedule = lesson.schedule;
				this.$store.commit('ui/showNotification', {
					notification: 'The lesson has started. Play the activities to continue.'
				});
			},
			async endLesson () {
				const lesson = await api.teaching.updateLessonById({
					lessonId: this.getLessonId,
					lesson: {
						...this.lesson,
						schedule: {
							...this.lesson.schedule,
							endedAt: this.getISO()
						}
					}
				});
				if (!lesson) {
					return false;
				}
				this.lesson.schedule = lesson.schedule;
				this.$store.commit('ui/showNotification', {
					notification: 'The lesson has ended. You can now upload a recording or add a Zoom download link.'
				});
			}
		}
	};

</script>

<style lang="scss" scoped>
	[data-component='lesson-action-groups'] {
	}
</style>
