<template>
	<div data-component="lesson-resources-by-categories">
		<template v-if="getResourcesGroupedByCategory.length">
			<div
				v-for="(category, key) in getResourcesGroupedByCategory"
				:key="key"
				data-element="lesson-resource-category"
			>
				<h2 data-element="heading">
					{{ category.name }}
				</h2>
				<div
					v-for="(resource, key) in category.resources"
					:key="key">
					<slot :resource="resource" />
				</div>
			</div>
		</template>
		<template v-else>
			<slot name="empty-state" />
		</template>
	</div>
</template>

<script>

	import { resourceCategoryOptions } from '@/consts';

	export default {
		components: {},
		props: {
			media: {
				type: Array,
				default: () => ([])
			}
		},
		computed: {
			getResourcesGroupedByCategory () {
				const groupedByCategory = this.media.reduce((acc, current) => {
					acc[current?.config?.type] = [...acc[current?.config?.type] || [], current];
					return acc;
				}, {});

				return resourceCategoryOptions
					.map(category => {
						if (groupedByCategory[category.value]?.length) {
							return {
								name: category.text,
								resources: groupedByCategory[category.value]
							};
						}
					}).filter(Boolean);
			}
		},
		methods: {}
	};

</script>

<style lang="scss" scoped>

	[data-component='lesson-resources-by-categories'] {
		[data-element='lesson-resource-category'] {
			[data-element='heading'] {
				font-size: rem(18);
				color: $c-brand-blue;
				margin-bottom: rem(8);
			}
			&:not(:first-child) {
				margin-top:rem(8);
			}
		}
	}

</style>
