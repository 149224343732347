<template>
	<div data-component="create-resource-modal">
		<resource-details-form
			:isWaiting="isWaiting"
			@save="uploadMedia"
			@cancel="closeModal"
		/>
	</div>
</template>

<script>

	import ResourceDetailsForm  from '@/components/lessons/ResourceDetailsForm';
	import routeParams from '@/mixins/routeParams';
	import { defaultResourceCategory } from '@/consts';
	import api from '@/services/api';
	import { v4 as uuid } from 'uuid';

	export default {
		components: {
			ResourceDetailsForm
		},
		mixins: [routeParams],
		props: {
			lesson: {
				type: Object,
				default: undefined
			}
		},
		data: () => ({
			isWaiting: false
		}),
		methods: {
			closeModal () {
				this.$emit('close');
			},
			async uploadMedia (media) {
				this.isWaiting = true;
				const formData = media.form;
				const config = {
					type: media.category || defaultResourceCategory,
					uploadUuid: uuid()
				};
				formData.append('config', JSON.stringify(config));
				formData.append('description', media.description || '');

				const response = await api[this.getApiRoot].createMediaByLessonId({
					lessonId: this.getLessonId,
					media: formData
				});

				if (response) {
					this.lesson.media = [
						...this.lesson.media,
						response
					];
					this.isWaiting = false;
					this.$store.commit('ui/showNotification', {
						notification: 'Upload complete 👍'
					});
					this.$emit('close');
				} else {
					console.error('Something went wrong. Empty response received.');

					this.$store.commit('ui/showNotification', {
						notification: 'The file could not be uploaded. Please try again.'
					});
				}
			}
		}
	};
</script>

<style lang="scss" scoped>
</style>
