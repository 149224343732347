<template>
	<div data-component="edit-resource-modal">
		<resource-details-form
			isEditMode
			:resource="getResource"
			:isWaiting="isWaiting"
			@save="editMedia"
			@cancel="closeModal"
		/>
	</div>
</template>

<script>

	import ResourceDetailsForm  from '@/components/lessons/ResourceDetailsForm';
	import routeParams from '@/mixins/routeParams';
	import api from '@/services/api';

	export default {
		components: {
			ResourceDetailsForm
		},
		mixins: [routeParams],
		props: {
			lesson: {
				type: Object,
				default: undefined
			},
			editableMediaId: {
				type: [Number, undefined],
				default: undefined
			}
		},
		data: () => ({
			isWaiting: false
		}),
		computed: {
			getResource () {
				return this.lesson.media?.find(item => item.id === this.editableMediaId);
			}
		},
		methods: {
			closeModal () {
				this.$emit('close');
			},
			async editMedia (media) {
				this.isWaiting = true;
				const config = {
					...this.getResource.config,
					type: media.category
				};

				const response = await api[this.getApiRoot].updateMediaByLessonIdAndMediaId({
					lessonId: this.getLessonId,
					mediaId: this.getResource.id,
					media: {
						config,
						description: media.description
					}
				});

				if (response) {
					this.lesson.media = [...this.lesson.media].map((item) => {
						if (item.id === response.id) {
							return response;
						}
						return item;
					});
					this.isWaiting = false;
					this.$store.commit('ui/showNotification', {
						notification: 'The resource was successfully edited.'
					});
					this.$emit('close');
				} else {
					console.error('Something went wrong. Empty response received.');
				}
			}
		}
	};
</script>

<style lang="scss" scoped>
</style>
