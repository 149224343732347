<template>
	<div data-component="recording-edit-modal">
		<validation-observer
			data-element="form"
			tag="form"
			ref="form"
			@submit.prevent="uploadMedia"
		>
			<upload
				fileType="mp4"
				:helpText="uploadHelpText"
				:form.sync="form"
				:media="getMedia"
				:isWaiting="isWaiting"
				@deleteMedia="deleteMedia"
			/>
			<actions
				:actions="getActions"
				@actionClick="onActionClick"
				:isWaiting="isWaiting"
			/>
		</validation-observer>
	</div>
</template>

<script>

	import Upload       from '@/components/uploads/Upload';
	import Actions      from '@/components/ui/Actions';
	import routeParams  from '@/mixins/routeParams';
	import actionClick  from '@/mixins/actionClick';
	import api          from '@/services/api';

	export default {
		components: {
			Upload,
			Actions
		},
		mixins: [routeParams, actionClick],
		props: {
			lesson: {
				type: Object,
				default: undefined
			}
		},
		data: () => ({
			form:       undefined,
			isWaiting:  undefined,
			uploadHelpText: [
				'Only mp4 files can be uploaded',
				'Should be no more than 500MB'
			]
		}),
		computed: {
			getMedia () {
				return this.lesson?.media?.filter((media) => {
					return media.config.type === 'recording';
				});
			},
			getActions () {
				return {
					primary: [
						(!this.getMedia.length ?
							{
								text:  'Upload',
								type:  'submit',
								btnId: 'upload'
							} : false
						)
					].filter(Boolean),
					secondary: [
						{
							text: 'Cancel',
							actionId: 'closeModal'
						}
					]
				};
			}
		},
		created () {
		},
		methods: {
			closeModal () {
				this.$emit('close');
			},
			async uploadMedia () {
				if (!this.form) {
					return;
				}
				this.isWaiting = 'upload';
				const formData = this.form;
				formData.append('config', '{ "type": "recording" }');
				const media = await api[this.getApiRoot].createMediaByLessonId({
					lessonId: this.getLessonId,
					media: formData
				});
				if (!media) {
					this.isWaiting = undefined;
					this.$store.commit('ui/showNotification', {
						notification: 'The file could not be uploaded. Please try again.'
					});
					return false;
				}
				this.lesson.media = [
					media
				];
				this.isWaiting = undefined;
				this.$store.commit('ui/showNotification', {
					notification: 'The recording has been saved.'
				});
				this.$emit('close');
			},
			async deleteMedia () {
				this.isWaiting = 'deleteMedia';
				if (!this?.lesson?.media?.[0]?.id) {
					this.isWaiting = undefined;
					return false;
				}
				const response = await api[this.getApiRoot].deleteMediaByLessonIdAndMediaId({
					lessonId: this.getLessonId,
					mediaId: this.lesson.media[0].id
				});
				if (!response) {
					this.isWaiting = undefined;
					return false;
				}
				this.isWaiting = undefined;
				this.lesson.media = [];
				this.$store.commit('ui/showNotification', {
					notification: 'Media has been deleted'
				});
			}
		}
	};
</script>

<style lang="scss" scoped>
	[data-component='recording-edit-modal'] {
	}
</style>
