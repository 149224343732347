<template>
	<div
		v-if="getShowResources"
		data-component="lesson-resources"
	>
		<lesson-resources-by-categories :media="getMedia">
			<template v-slot="{ resource }">
				<lesson-resource-item :resource="resource" />
			</template>
		</lesson-resources-by-categories>
	</div>
</template>

<script>

	import LessonResourcesByCategories from '@/components/lessons/LessonResourcesByCategories';
	import LessonResourceItem from '@/components/lessons/LessonResourceItem';
	import routeParams from '@/mixins/routeParams';

	export default {
		components: {
			LessonResourcesByCategories,
			LessonResourceItem
		},
		mixins: [routeParams],
		props: {
			lesson: {
				type: Object,
				default: () => ({})
			}
		},
		data () {
			return {
				hiddenCategories: [],
				hiddenCategoriesByCondition: [
					'hand_record'
				]
			};
		},
		computed: {
			getShowAllResources () {
				if (this.getContext === 'browseCourses' ||
					this.getContext === 'myCourses') {
					return false;
				}
				return true;
			},
			getAreLessonDealsCompleted () {
				return this.lesson.deals.every(deal => deal.isComplete);
			},
			getLessonIsLive () {
				return this.lesson.live;
			},
			getMedia () {
				const media = this.lesson?.media || [];
				return media.filter((item) => {
					if (this.hiddenCategoriesByCondition.includes(item?.config.type)) {
						return [
							this.getShowAllResources,
							this.getAreLessonDealsCompleted,
							this.getLessonIsLive
						].some(r => r);
					}
					return !this.hiddenCategories.includes(item?.config.type);
				});
			},
			getShowResources () {
				if (!this.getMedia?.length) {
					return false;
				}
				return true;
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='lesson-resources'] {
		padding:rem(14);
		background:$c-brand-grey-lighter-8;
		border-radius:8px;
	}

</style>
